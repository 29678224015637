import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CommonHttpService } from './common-http.service';
import { LocalStorageService } from './local-storage.service';
import constant from './../app.constant';
import { ToastrService } from 'ngx-toastr';
import { NavService } from './../shared/services/nav.service';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    public showLoader: boolean = false;
    public userDetails: any = {};
    notifymessage: any;

    constructor(
        private http: CommonHttpService,
        private router: Router,
        private localStorage: LocalStorageService,
        public toster: ToastrService,
        public navService: NavService
    ) { }
    login(data: any) {

        return this.http.postData(constant.Service.MainMaster.BaseUrl + constant.Service.MainMaster.Auth.Login, data).subscribe((res: any) => {
            if (res.status === true) {
                if (res && res.data) {
                    const userData: any = res.data
                    this.toster.success('Login Successful');
                    this.localStorage.addItem(constant.localStorage.token, userData.token);
                    this.getUserDetails(userData);
                    this.router.navigate(['/orders/list']);
                }
            } else {
                this.toster.error(res.message);
            }

        }, err => {
            // this.appComponent.openSnackBar('Login Failed, Please Enter Correct credentials', 'Failed');
            this.router.navigate(['']);
        });

    }
    loginToken() {
        if (this.localStorage.getItem(constant.localStorage.token)) {
            this.router.navigate(['/orders/list']);
        } else {
            this.router.navigate(['/auth/login']);
        }
    }
    // tokenSetAndCheckInLocalStorage(token: any) {
    //     if (token) {
    //         this.localStorage.addItem(constant.localStorage.token, token);
    //     } else if (!this.localStorage.getItem(constant.localStorage.token)) {
    //         this.toster.error('Session Expired');
    //         // location.href = constant.Client.Auth.BaseUrl;
    //     }
    // }
    getUserDetails(userDetails: any) {
        this.localStorage.addItem(constant.localStorage.user_name, userDetails?.full_name);
        this.localStorage.addItem(constant.localStorage.first_name, userDetails?.first_name);
        this.localStorage.addItem(constant.localStorage.last_name, userDetails?.last_name);
        this.localStorage.addItem(constant.localStorage.email, userDetails?.email);
        this.localStorage.addItem(constant.localStorage.phone, userDetails?.phone);
        this.localStorage.addItem(constant.localStorage.user_id, userDetails?.user_id);
        this.localStorage.addItem(constant.localStorage.role_id, userDetails?.role_id);
    }

    logout() {
        if (this.localStorage.getItem(constant.localStorage.token)) {
            this.http.get(constant.Service.MainMaster.BaseUrl + constant.Service.MainMaster.Auth.Logout).subscribe((response: any) => {
                this.toster.success('Logout Success');
                this.logoutWithoutBackendHit();
            }, err => {
                this.toster.success('Logout Success');
                this.logoutWithoutBackendHit();
            })
        } else {
            this.logoutWithoutBackendHit();
        }
    }

    logoutWithoutBackendHit() {
        this.localStorage.clearAllItem();
        this.router.navigateByUrl('/');
    }

}
