<div>
    <label [for]="childFormControlName">{{ label }}</label>
    <input [type]="type" [id]="childFormControlName" [formControl]="control" [placeholder]="placeholder" />

    <div *ngIf="control.invalid && control.touched || submitted">
        <div class="error" *ngIf=" control.errors?.['required']">
            {{ label }} is required
        </div>
        <div class="error" *ngIf="control.errors?.['email']">
            Please enter a valid email address
        </div>
        <div class="error" *ngIf="control.errors?.['pattern']">
            Invalid input format
        </div>
    </div>
</div>