import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-common-input-box',
  // standalone: true,
  // imports: [],
  templateUrl: './common-input-box.component.html',
  styleUrl: './common-input-box.component.scss'
})
export class CommonInputBoxComponent {
  @Input() label: string = '';
  @Input() type: string = 'text';
  @Input() placeholder: string = '';
  @Input() childFormControlName: AbstractControl;
  @Input() formGroup!: FormGroup;
  @Input() submitted: boolean = false;
  constructor() {
    //console.log(this.label, this.type, this.placeholder)
  }
  get control(): FormControl {
    return this.childFormControlName as FormControl;
  }
}
