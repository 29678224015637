import { Routes } from "@angular/router";


export const content: Routes = [
  // {
  //   path: "simple-page",
  //   loadChildren: () => import("../../components/simple-page/simple-page.module").then((m) => m.SimplePageModule),
  // },
  // {
  //   path: "single-page",
  //   loadChildren: () => import("../../components/single-page/single-page.module").then((m) => m.SinglePageModule),
  // },
  {
    path: "",
    loadChildren: () => import("../../components/components.module").then((m) => m.ComponentsModule),
  },
];

